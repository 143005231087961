var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    !_vm.$apollo.loading
      ? _c("div", [_c("router-view")], 1)
      : _c("div", [_c("content-loading")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }