<script>
import Layout from '@layouts/main';
import ContentLoading from '@components/content-loading';

export default {
  name: 'Drivers',
  page: {
    title: 'Motoristas',
  },
  components: {
    Layout,
    ContentLoading,
  },
};
</script>

<template>
  <Layout>
    <!--header driver-->
    <div v-if="!$apollo.loading">
      <!--table list drivers-->
      <router-view />
    </div>
    <div v-else>
      <content-loading />
    </div>
  </Layout>
</template>
